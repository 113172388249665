/* ================================
Global Styles
================================ */

/* Reset */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* Base Styles */
html, body {
  background-color: #FAF9F1;
  width: 100%;
  max-width: 100%;
  color: #333;
  font-family: 'Metropolis', sans-serif;
  height: 100%;
  scroll-behavior: smooth;
  overflow-x: hidden;
  box-sizing: border-box;
}

#root {
  height: 100%;
}

img {
  max-width: 100%;
  height: auto;
}

/* Full-Height Utility */
.full-height {
  height: 100vh;
}

/* Global Container Styling */
.container {
  /* max-width: 1600px; */
  padding-left: 44px; /* Apply consistent left padding */
  padding-right: 44px; /* Apply consistent right padding */
  margin-left: auto; /* Center the container horizontally */
  margin-right: auto; /* Center the container horizontally */
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  .container {
    padding-left: 10px;
    padding-right: 10px;
  }
}


/* ================================
Typography Styles
================================ */

h1 {
  font-size: 2.5rem;
  font-weight: bold;
  text-align: center;
  margin-bottom: 20px;
  color: #2b2d42;
}

h2 {
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 16px;
  color: #2b2d42;
}

h3 {
  font-size: 1.75rem;
  font-weight: bold;
  margin-bottom: 12px;
  color: #2b2d42;
}

h4 {
  font-size: 1.5rem;
  text-align: center;
  margin-bottom: 20px;
  color: #2b2d42;
}

h5 {
  font-size: 1.25rem;
  font-weight: bold;
  margin-bottom: 8px;
  padding-left: 16px;
  padding-bottom: 16px;
  color: #333;
}

h6 {
  font-size: 1rem;
  font-weight: extra-light;
  margin-bottom: 6px;
  color: #333;
  /* color: #F4F1DE; */
}



p {
  font-size: 1.2rem;
  line-height: 1.6;
  text-align: center;
  margin-bottom: 20px;
  color: #2b2d42;
}

small {
  font-size: 0.875rem;
}

/* Justified Text */
.justify-text {
  text-align: justify;
}

/* ================================
Animation
================================ */

#eagle-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none; /* Prevent blocking interactions */
  z-index: 9999; /* Ensure it appears above all other elements */
}
#metric-container {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden; /* Ensure content remains within bounds */
  background-color: transparent; /* Optional styling */
  z-index: 9999; /* Ensure it appears above all other elements */
}



/* ================================
Tools Page
================================ */


.tools-sidebar {
  /* position: fixed; */
  position: sticky;
  top: 0;
  left: 0;
  width: 300px; /* Sidebar width */
  height: 100%; /* Full viewport height */
  overflow-y: auto; /* Scrollable if content exceeds height */
  background-color: #FAF9F1; /* Updated background color */
  border-right: 1px solid #ddd;
  padding: 20px;
  padding-bottom: 100px;
  box-sizing: border-box;
  z-index: 1; /* Place sidebar below other content */
}

.sidebar-title {
  display: flex;
  color: #D1495B;
  justify-content: space-between;
  align-items: center;
  font-weight: bold;
  
  padding: 10px 0;
  cursor: pointer;
}

.sidebar-link {
  padding-left: 20px;
  font-size: 1rem;
  color: #333; /* Default link color */
  text-decoration: none;
  cursor: pointer;
  display: block; /* Ensures full clickable area */
}
.sidebar-coming-soon {
  padding-left: 20px;
  color: #D1495B;
  font-size: 1rem;
  color: #333; /* Default link color */
  text-decoration: none;
  cursor: pointer;
  display: block; /* Ensures full clickable area */
}

.sidebar-link:hover {
  color: #000; /* Hover effect */
  background-color: #e0e0e0; /* Highlighted background */
}

/* General Tools Page Layout */

.tools-layout {
  display: flex; /* Sidebar and content side by side */
  height: 100vh; /* Occupy the full browser height */
  overflow: hidden; /* Prevent scrolling outside */
}

/* Content Area Styling */
.tools-content {
  flex: 1; /* Take up remaining width */
  padding-left: 24px; /* Updated left padding */
  padding-right: 40px; /* Updated right padding */
  overflow-y: auto; /* Scrollable content */
  box-sizing: border-box;
  background-color: #f9f9f9;
}
/* Login Call to Action */
.login-call-to-action {
  padding: 20px;
  margin-top: 20px;
  font-size: 16px;
  color: #2b2d42;
  text-align: center;
}

.login-call-to-action .highlighted-text {
  font-weight: bold;
  color: #FABC18; /* Example highlight color */
}

/* Tools Disclaimer */
.tools-disclaimer {
  margin-top: 20px;
  padding: 20px;
  background-color: #f7f7f7;
  color: #2b2d42;
  border-radius: 8px;
  text-align: justify;
  line-height: 1.6;
  font-style: italic;
  font-size: 0.9rem;
  color: #6c757d; /* Slightly grey color */
  line-height: 1.5;
  margin-top: 10px;
}

/* Tools Page Background Color */
.tools-background {
  background-color: #FAF9F1;
}


/* ================================
Section Background Colors
================================ */

.bg-landing {
  background-color: #f0f4f8;
}

/* .bg-about {
  background-color: #e0f7fa;
} */

/* .bg-partners {
  background-color: #ffe0b2;
} */

/* .bg-signup {
  background-color: #e1bee7;
} */

/* .bg-other {
  background-color: #c5cae9;
} */

/* ================================
Layout Styles
================================ */

.section {
  padding: 20px;
}

.left-content,
.right-content {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
}

.vertical-space {
  height: 16px;
}

.vertical-space-24 {
  height: 24px;
}

.horizontal-line {
  width: calc(100% - 32px); /* 100% width minus 16px padding on each side */
  border: none;
  border-top: 1px solid #F4F1DE;
  margin: 0 16px; /* 16px padding left and right */
}
.horizontal-line2 {
  width: calc(100% - 32px); /* 100% width minus 16px padding on each side */
  border: none;
  border-top: 1px solid #F4F1DE;
  margin: 0 16px; /* 16px padding left and right */
}

hr.horizontal-line {
  border: none;
  border-top: 1px solid #2b2d42;
  margin: 0;
}
hr.horizontal-line2 {
  border: none;
  border-top: 1px solid #f4f1de;
  margin: 0;
}

.horizontal-stack {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 20px; /* Adjust the gap between elements as needed */
}

/* ================================
Header Styles
================================ */


/* Header Styles */
.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  padding: 20px 44px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  transition: background-color 0.3s ease-in-out;
}


.header-logo {
  max-width: 180px;
  width: 100%;
  height: auto;
}

/* ================================
Landing Styles
================================ */

.section {
  padding: 20px;
}

.full-height {
  height: 100vh;
}

.bg-landing {
  background-color: #f0f4f8;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

@media (min-width: 768px) {
  .bg-landing {
    flex-direction: row;
  }
}
/* Landing Video Styling */
.landing-video {
  width: 100%;
  max-width: 300px;
  height: auto;
  border-radius: 12px;
  object-fit: cover;
  margin-top: 20px;
}

/* Landing Content Layout */
.landing-left,
.landing-right {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
}

.landing-left {
  text-align: right;
}

.landing-left h1,
.landing-left h4,
.landing-left p {
  text-align: right;
}

/* ================================
About Project Styles
================================ */

/* About Project Section */
.about-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 40px;
}

@media (min-width: 768px) {
  .about-container {
    flex-direction: row;
  }
}

.about-left,
.about-right {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
}

.about-image {
  width: 100%;
  max-width: 300px;
  object-fit: cover;
  border-radius: 12px;
  margin-bottom: 20px;
}

/* ================================
PartnerTile Styles
================================ */

.partner-tile {
  text-decoration: none;
  width: 100%;
  cursor: pointer;
  
}

.tile-container {
  padding: 20px;
  text-align: center;
  display: flex;
  flex-direction: column;

  align-items: center;
  border-radius: 24px;
  transition: transform 0.3s, box-shadow 0.3s;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.tile-container:hover {
  transform: scale(1.05);
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.2);
}

.tile-image {
  width: calc(100% - 16px);
  height: calc(100% - 16px);
  object-fit: cover;
  padding: 8px 8px 0;
  margin-bottom: 10px;
  border-radius: 8px;
}

.tile-title {
  margin-top: 10px;
}

.tile-description {
  color: #666;
}

/* ================================
Signup Section Styles
================================ */

.signup-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 40px;

}

@media (min-width: 768px) {
  .signup-container {
    flex-direction: row;
  }
}

.signup-left,
.signup-right {
  flex: 1;
  padding: 20px;
  text-align: center;
}

.signup-input {
  background-color: transparent;
  color: #2B2D42;
}

.signup-input .MuiOutlinedInput-root {
  &.Mui-focused fieldset {
    border-color: #2B2D42;
  }
}

.signup-input .MuiInputLabel-root {
  color: #2B2D42;
}

.signup-input .MuiInputLabel-root.Mui-focused {
  color: #2B2D42;
}

.signup-input .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline {
  border-color: #D1495B;
}

.signup-input .MuiFormHelperText-root.Mui-error {
  color: #D1495B;
}

.signup-input input:valid + fieldset {
  border-color: #282D42;
}

/* ================================
Other Section Styles
================================ */

.other-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  padding: 20px;
}

.other-image {
  width: 80vw; /* 80% of the viewport width */
  height: 80vh; /* 80% of the viewport height */
  max-width: 80%; /* Ensure it does not exceed 80% of its container width */
  max-height: 80%; /* Ensure it does not exceed 80% of its container height */
  object-fit: contain;
  margin: 20px auto;
  display: block;
}

/* ================================
Footer Styles
================================ */

footer {
  background-color: #f0f4f8;
  padding: 20px;
  position: relative; /* Ensure the footer sits above the sidebar */
  z-index: 2;
}

footer .MuiIconButton-root {
  color: #555;
}

footer .MuiIconButton-root:hover {
  color: #000;
}

footer a {
  text-decoration: none;
}

/* ================================
Scroll to Top Button
================================ */

/* Scroll to Top Button Styles */

.scroll-to-top {
  position: fixed; /* Fixed positioning to float over the viewport */
  bottom: 40px; /* 40px from the bottom of the viewport */
  left: 40px; /* 24px from the right of the viewport */
  width: 50px;
  height: 50px;
  background-color: #fff;
  border-radius: 50%;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  opacity: 0;
  visibility: hidden;
  z-index: 9999; /* Ensure it's above other elements */
  transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out;
}

/* Visible state for the button */
.scroll-to-top.visible {
  opacity: 1;
  visibility: visible;
}

.chevron-icon {
  width: 20px;
  height: 20px;
  fill: #000;
}

/* ================================
Animations
================================ */

@keyframes rotate {
  0% {
    stroke-dashoffset: 0;
  }
  100% {
    stroke-dashoffset: 1;
  }
}

.MuiTableHead-root {
  background-color: #5a5a5a;
}

.MuiTableCell-head {
  color: #f4f1de;
}

.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: #fabc18;
}

.MuiInputLabel-root.Mui-focused {
  color: #fabc18;
}

.MuiSelect-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: #fabc18;
}

.MuiSelect-root.Mui-focused .MuiInputLabel-root {
  color: #fabc18;
}

.MuiRadio-root.Mui-checked {
  color: #fabc18;
}

/* Collapsible Sidebar */
@media (max-width: 800px) {
  .tools-sidebar {
    position: fixed;
    left: -300px; /* Hide sidebar by default */
    transition: left 0.3s ease-in-out;
    z-index: 1000;
  }

  .tools-sidebar.open {
    left: 0; /* Show sidebar when open */
  }

  .sidebar-toggle {
    position: fixed;
    bottom: 20px;
    left: 20px;
    z-index: 1001;
    background-color: #fff;
    border: none;
    border-radius: 50%;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
}

@media (min-width: 800px) {
  .sidebar-toggle {
    display: none; /* Hide the toggle button on large screens */
  }
}

/* Popup Styles */
.popup {
  position: fixed;
  width: 500px;
  height: 500px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10000;
  border-radius: 8px;
  text-align: center;
  font-size: 1.5rem;
  color: #333;
}




